import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';

export default function initGlightbox() {
    GLightbox({
        selector: '.glightbox',
        preload: true,
        // Plyr captions
        plyr: {
            config: {
                captions: {
                    active: true,
                    defaultActive: true,
                    language: 'fr',
                },
                youtube: {
                    cc_load_policy: 1,
                },
            },
        },
    });
}
