import { CountUp } from 'countup.js/dist/countUp.min.js';

export default function initCountUp() {
    const counters = document.querySelectorAll('[data-count-up]');

    counters.forEach((counter) => {
        const options = JSON.parse(counter.getAttribute('data-count-up'));

        const countUp = new CountUp(counter, counter.textContent, options);

        countUp.start();
    });
}
