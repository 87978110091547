import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function initGsapAnimsAboutPage() {
    const isTablet = window.matchMedia('(max-width: 1023px)').matches;
    const isTablet1 = window.matchMedia('(max-width: 1022px)').matches;
    const isTablet2 = window.matchMedia('(max-width: 1020px)').matches;

    setTimeout(() => {
        const aboutHero = document.querySelector('.about-hero');

        if (aboutHero) {
            // console.log('initGsapAnimsAboutPage');
            gsap.to(aboutHero, {
                scrollTrigger: {
                    trigger: aboutHero,
                    start: 'top top',
                    end: 'bottom top',
                    scrub: 1.245,
                },
                y: '12%',
                ease: 'none',
            });
        }

        const gradientEffect = document.querySelector('.gradient-effect');

        if (gradientEffect) {
            // console.log('initGsapAnimsAboutPage');
            gsap.to(gradientEffect, {
                scrollTrigger: {
                    trigger: gradientEffect,
                    start: 'top top',
                    end: 'bottom bottom',
                    scrub: 1.25,
                },
                y: '-2000px',
                ease: 'none',
            });
        }

        const histoireSplitRightRail = document.querySelector('.histoire-split .right');
        const histoireSplitLeftRailSlide = document.querySelector('.histoire-split .right .slide');

        if (histoireSplitRightRail && !isTablet2) {
            gsap.to(histoireSplitRightRail, {
                scrollTrigger: {
                    trigger: histoireSplitRightRail,
                    start: 'top top',
                    end: () =>
                        `+=${
                            histoireSplitRightRail.offsetHeight -
                            histoireSplitLeftRailSlide.offsetHeight
                        }`,
                    pin: true,
                    anticipatePin: 1,
                },
                ease: 'none',
            });
        }

        const rowsTriggers = document.querySelectorAll('.rows .row-trigger');

        if (rowsTriggers) {
            rowsTriggers.forEach((row) => {
                row.addEventListener('click', () => {
                    const rowSibling = row.nextElementSibling;

                    if (rowSibling) {
                        rowSibling.classList.toggle('is-active');
                        row.classList.toggle('is-active');
                    }
                });
            });
        }

        const hoverLargerEffectCols = document.querySelectorAll('.hover-larger-effect .col');

        if (hoverLargerEffectCols) {
            hoverLargerEffectCols.forEach((col) => {
                const hoverLargerEffectWrap = col.closest('.hover-larger-effect');

                col.addEventListener('mouseenter', () => {
                    hoverLargerEffectWrap.classList.add('is-hovered');

                    hoverLargerEffectCols.forEach((el) => {
                        el.classList.remove('is-hovered');
                    });

                    col.classList.add('is-hovered');
                });
                col.addEventListener('mouseleave', () => {
                    hoverLargerEffectWrap.classList.remove('is-hovered');

                    hoverLargerEffectCols.forEach((el) => {
                        el.classList.remove('is-hovered');
                    });
                });
            });
        }
    }, 745);
}
