import Flickity from 'flickity';

import 'flickity/dist/flickity.min.css';

export default function initFlickitySliders() {
    const flickitySliders = document.querySelectorAll('[data-init-flickity]');

    flickitySliders.forEach((slider) => {
        let options = JSON.parse(slider.getAttribute('data-init-flickity'));
        options = {
            ...options,
            arrowShape: {
                x0: 25,
                x1: 65,
                y1: 40,
                x2: 65,
                y2: 35,
                x3: 30,
            },
        };

        const flky = new Flickity(slider, options);

        const sliderControls = slider.parentNode.querySelector('.slider-control');

        if (sliderControls) {
            const prevBtn = sliderControls.querySelector('.prev');
            const nextBtn = sliderControls.querySelector('.next');

            prevBtn.addEventListener('click', () => {
                flky.previous();
            });

            nextBtn.addEventListener('click', () => {
                flky.next();
            });
        }

        const sliderPagination = slider.parentNode.querySelector('.pagination');

        if (sliderPagination) {
            const paginationItems = sliderPagination.querySelectorAll('.dot');

            paginationItems.forEach((item, index) => {
                item.addEventListener('click', () => {
                    flky.select(index);
                    item.classList.add('is-active');
                });
            });

            flky.on('select', () => {
                const currentSlide = flky.selectedIndex;

                if (flky.selectedIndex === 0) {
                    sliderControls.querySelector('.prev').classList.add('is-off');
                } else {
                    sliderControls.querySelector('.prev').classList.remove('is-off');
                }

                if (flky.selectedIndex === flky.slides.length - 1) {
                    sliderControls.querySelector('.next').classList.add('is-off');
                } else {
                    sliderControls.querySelector('.next').classList.remove('is-off');
                }

                if (sliderPagination) {
                    const paginationItems = sliderPagination.querySelectorAll('.dot');

                    paginationItems.forEach((item, index) => {
                        if (index === currentSlide) {
                            item.classList.add('is-active');
                        } else {
                            item.classList.remove('is-active');
                        }
                    });
                }
            });
        }

        setTimeout(() => {
            flky.resize();

            setTimeout(() => {
                flky.resize();
            }, 2000);
        }, 1000);
    });
}
