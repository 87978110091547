import initAddToFavorite from '../js-components/addToFavorite';

export default function initModals() {
    const modalTriggers = document.querySelectorAll('[data-open-dialog]');
    const modalsContainer = document.querySelector('#dialog_layer');

    modalTriggers.forEach((trigger) => {
        trigger.addEventListener('click', (e) => {
            e.preventDefault();
            const modalId = trigger.getAttribute('data-open-dialog');
            const modal = document.getElementById(modalId);

            if (!modal) {
                return;
            }

            const dataLoadFavoris = trigger.getAttribute('data-load-favoris');

            if (dataLoadFavoris && dataLoadFavoris === 'favoris') {
                fetchFavoris(modal);
            }

            modalsContainer.classList.remove('hidden');
            modal.classList.remove('hidden');

            const tabIndexElements = modal.querySelectorAll('[tabindex="-1"]');

            tabIndexElements.forEach((el) => {
                el.setAttribute('tabindex', '0');
            });

            tabIndexElements[0].focus();

            const modalCloseBtn = modal.querySelector('.close');

            modalCloseBtn.addEventListener('click', () => {
                modal.classList.add('hidden');
                modalsContainer.classList.add('hidden');

                // Refocus btn that opened modal
                trigger.focus();

                tabIndexElements.forEach((el) => {
                    el.setAttribute('tabindex', '-1');
                });
            });

            // Close modal on ESC key
            document.addEventListener('keydown', (e) => {
                if (e.key === 'Escape') {
                    modal.classList.add('hidden');
                    modalsContainer.classList.add('hidden');

                    tabIndexElements.forEach((el) => {
                        el.setAttribute('tabindex', '-1');
                    });
                }
            });

            // Close modal on click outside
            modalsContainer.addEventListener('click', (e) => {
                if (e.target === modalsContainer) {
                    modal.classList.add('hidden');
                    modalsContainer.classList.add('hidden');

                    // Refocus btn that opened modal
                    trigger.focus();

                    tabIndexElements.forEach((el) => {
                        el.setAttribute('tabindex', '-1');
                    });
                }
            });

            // Keep focus inside modal
            modal.addEventListener('keydown', (e) => {
                const focusableElements = modal.querySelectorAll(
                    'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select'
                );

                const firstFocusableElement = focusableElements[0];
                const lastFocusableElement = focusableElements[focusableElements.length - 1];

                const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

                if (!isTabPressed) {
                    return;
                }

                if (e.shiftKey) {
                    if (document.activeElement === firstFocusableElement) {
                        lastFocusableElement.focus();
                        e.preventDefault();
                    }
                } else {
                    if (document.activeElement === lastFocusableElement) {
                        firstFocusableElement.focus();
                        e.preventDefault();
                    }
                }
            });
        });
    });

    if (!document.querySelector('[data-share-copy-link]')) {
        return;
    }

    function shareBtnsActions() {
        const copyLinkBtn = document.querySelector('[data-share-copy-link]');
        const linkedInLinkBtn = document.querySelector('[data-share-linkedin-link]');
        const facebookLinkBtn = document.querySelector('[data-share-facebook-link]');
        const twitterLinkBtn = document.querySelector('[data-share-twitter-link]');

        if (!copyLinkBtn || !linkedInLinkBtn || !facebookLinkBtn || !twitterLinkBtn) {
            return;
        }

        const closeModal = () => {
            const modal = document.querySelector('#dialog1');
            modal.querySelector('.close').click();
        };

        const urls = {
            facebook:
                'https://www.facebook.com/sharer/sharer.php?u=' +
                encodeURI(window.location.href) +
                '&title=' +
                encodeURI(document.querySelector('h1').textContent) +
                '',
            twitter:
                'https://twitter.com/intent/tweet?url=' +
                encodeURI(window.location.href) +
                '&text=' +
                encodeURI(document.querySelector('h1').textContent) +
                '&via=',
            linkedin:
                'https://www.linkedin.com/shareArticle?url=' +
                encodeURI(window.location.href) +
                '&title=' +
                encodeURI(document.querySelector('h1').textContent) +
                '',
        };

        const openSharePopup = (url) => {
            const width = 600;
            const height = 400;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;
            window.open(url, 'Share', `width=${width},height=${height},left=${left},top=${top}`);
        };

        copyLinkBtn.addEventListener('click', (e) => {
            e.preventDefault();
            /*global ClipboardItem*/
            const type = 'text/plain';
            const blob = new Blob([window.location.href], { type });
            const data = [new ClipboardItem({ [type]: blob })];
            navigator.clipboard.write(data);
            closeModal();
        });

        linkedInLinkBtn.addEventListener('click', (e) => {
            e.preventDefault();
            openSharePopup(urls.linkedin);
            closeModal();
        });

        facebookLinkBtn.addEventListener('click', (e) => {
            e.preventDefault();
            openSharePopup(urls.facebook);
            closeModal();
        });

        twitterLinkBtn.addEventListener('click', (e) => {
            e.preventDefault();
            openSharePopup(urls.twitter);
            closeModal();
        });
    }

    function fetchFavoris(modal) {
        const favoriteEvents = localStorage.getItem('gestev-favorite-events') || '[]';

        // Fetch '/evenements/parts/favoris' with ?ids=
        // ex: /evenements/parts/favoris?ids=["25","27","2397","5"]
        const url = '/evenements/parts/favoris?ids=' + favoriteEvents;

        fetch(url)
            .then((response) => response.text())
            .then((html) => {
                const parser = new DOMParser();
                const doc = parser.parseFromString(html, 'text/html');
                const eventsListingWrap = doc.querySelector('#favoris-results-items');

                const modalResultsZone = modal.querySelector('#favoris-results');

                modalResultsZone.innerHTML = eventsListingWrap.innerHTML;

                const dateRemoveFromFavorite = modalResultsZone.querySelectorAll(
                    '[data-remove-from-favorite]'
                );

                dateRemoveFromFavorite.forEach((btn) => {
                    btn.addEventListener('click', (e) => {
                        e.preventDefault();

                        const eventId = btn.getAttribute('data-remove-from-favorite');
                        const favoriteEvents =
                            JSON.parse(localStorage.getItem('gestev-favorite-events')) || [];

                        // Remove from favorite
                        const index = favoriteEvents.indexOf(eventId);

                        if (index > -1) {
                            favoriteEvents.splice(index, 1);
                        }

                        localStorage.setItem(
                            'gestev-favorite-events',
                            JSON.stringify(favoriteEvents)
                        );

                        setTimeout(() => {
                            initAddToFavorite();

                            setTimeout(() => {
                                fetchFavoris(modal);
                            }, 200);
                        }, 200);
                    });
                });
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    shareBtnsActions();
}
