export default function initScrollTo() {
    const links = document.querySelectorAll('[data-scroll-to]');
    const headerHeight = 0;

    links.forEach((link) => {
        link.addEventListener('click', (e) => {
            e.preventDefault();

            const target = document.querySelector(link.getAttribute('href'));
            let targetPosition = target.getBoundingClientRect().top + window.scrollY;
            targetPosition - headerHeight;

            // Add hash to current page url
            history.pushState(null, null, link.getAttribute('href'));

            setTimeout(() => {
                window.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth',
                });
            }, 1);
        });
    });

    const currrentPageHash = window.location.hash;

    if (currrentPageHash) {
        const target = document.querySelector(currrentPageHash);

        if (!target) {
            return;
        }

        const targetPosition = target.offsetTop - headerHeight;

        window.scrollTo({
            top: targetPosition,
            behavior: 'smooth',
        });
    }
}
