// import initEventsPageAndFilters from './js-components/eventsPageAndFilters';
import initGsapAnims from './js-components/gsapAnims';
import initGsapAnimsAboutPage from './js-components/gsapAnimsAboutPage';
import initFlickitySliders from './js-components/flickitySliders';
import initGlightbox from './js-components/glightbox';
import initModals from './js-components/modals';
import initAddToFavorite from './js-components/addToFavorite';
import initScrollTo from './js-components/scrollTo';
import initCountUp from './js-components/countUp';

class App {
    init() {
        // Add loaded to body and delete spinner
        document.body.classList.add('loaded');

        // initEventsPageAndFilters();
        initGsapAnims();
        initGsapAnimsAboutPage();
        initFlickitySliders();
        initGlightbox();
        initModals();
        initAddToFavorite();
        initScrollTo();
        initCountUp();

        this.initAOS();

        this.initFollowNav();

        const detailsHero = document.querySelector('.events-details-hero');
        const fixedBar = document.querySelector('.fixed-follow-bar');

        // When scroll past hero
        if (detailsHero && fixedBar) {
            const heroHeight = detailsHero.offsetHeight;

            window.addEventListener('scroll', () => {
                if (window.scrollY > heroHeight) {
                    fixedBar.classList.add('is-active');
                } else {
                    fixedBar.classList.remove('is-active');
                }
            });
        }

        const burger = document.querySelector('.burger');
        const mobileNav = document.querySelector('.mobile-nav');
        const mainNav = document.querySelector('.main-nav');
        const topNav = document.querySelector('.top-nav');

        burger.addEventListener('click', () => {
            burger.classList.toggle('is-active');
            mainNav.classList.toggle('is-active');
            mobileNav.classList.toggle('is-active');
            topNav.classList.toggle('is-active');
            document.body.classList.toggle('is-fixed');
        });

        const searchWrap = document.querySelector('.search-wrap');
        const submit = searchWrap.querySelector('form [type="submit"]');

        if (searchWrap && submit) {
            submit.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopPropagation();

                searchWrap.querySelector('form input').focus();

                searchWrap.classList.add('is-active');
                document.body.classList.add('is-search-open');
            });

            searchWrap.querySelector('form input').addEventListener('blur', () => {
                // searchWrap.classList.remove('is-active');
                // document.body.classList.remove('is-search-open');
            });

            // Close search on click outside
            document.addEventListener('click', (e) => {
                if (!searchWrap.contains(e.target)) {
                    searchWrap.classList.remove('is-active');
                    document.body.classList.remove('is-search-open');
                }
            });
        }

        const unfoldTriggers = document.querySelectorAll('[data-unfold-trigger]');

        unfoldTriggers.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                const uid = trigger.dataset.unfoldTrigger;

                const unfoldContent = document.querySelector(`[data-unfold-row="${uid}"]`);

                if (unfoldContent) {
                    unfoldContent.classList.toggle('is-active');
                    trigger.classList.toggle('is-active');
                }
            });
        });

        if (document.querySelector('.Lieux')) {
            document.querySelector('.Lieux').addEventListener('click', (e) => {
                const target = e.target;
                if (target.closest('.lieux-btns')) {
                    setTimeout(() => {
                        this.initAOS();
                        initFlickitySliders();
                        initGsapAnims();

                        const scrollToPosition =
                            document.querySelector('.lieux-split').getBoundingClientRect().top +
                            window.scrollY;

                        window.scrollTo({
                            top: scrollToPosition,
                            behavior: 'smooth',
                        });
                    }, 1500);
                }
            });
        }

        // HomePage video
        let isMobile = true;
        isMobile = window.matchMedia('(max-width: 767px)').matches;

        const videoRef = document.querySelector('[data-load-video]');

        if (!videoRef || isMobile.value) {
            return;
        }

        // Delay videos load
        setTimeout(() => {
            videoRef.setAttribute('src', videoRef.getAttribute('data-src'));
        }, 10);
    }

    initAOS() {
        setTimeout(() => {
            // In View Observer trigger Anims
            const animSelector = '[data-inview]';
            const animElements = [].slice.call(document.querySelectorAll(animSelector));

            const animObserver = new IntersectionObserver(function (elems) {
                elems.forEach(function (elem) {
                    if (elem.intersectionRatio > 0) {
                        elem.target.classList.add('is-in-view');
                    }
                });
            });

            setTimeout(function () {
                animElements.forEach(function (elem) {
                    animObserver.observe(elem);
                });
            }, 15);
        }, 15);

        setTimeout(() => {
            // In View Observer trigger Anims
            const animSelector = '[data-inline-inview]';
            const animElements = [].slice.call(document.querySelectorAll(animSelector));

            const animObserver = new IntersectionObserver(function (elems) {
                elems.forEach(function (elem) {
                    if (elem.intersectionRatio > 0) {
                        elem.target.classList.add('is-in-view');
                    }
                });
            });

            setTimeout(function () {
                animElements.forEach(function (elem) {
                    animObserver.observe(elem);
                });
            }, 15);
        }, 15);
    }

    initFollowNav() {
        const followNav = document.querySelector('.main-nav');

        if (!followNav) {
            return;
        }

        window.addEventListener('scroll', () => {
            if (window.scrollY > followNav.offsetHeight) {
                followNav.classList.add('is-scrolled');
            } else {
                followNav.classList.remove('is-scrolled');
            }
        });
    }
}

export default new App();
