import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default function initGsapAnims() {
    const isTablet = window.matchMedia('(max-width: 1023px)').matches;
    const isTablet1 = window.matchMedia('(max-width: 1022px)').matches;

    setTimeout(() => {
        // Scroller Horizontal text
        // const scroller = document.querySelector('.scroller');
        const liners = document.querySelectorAll('.liners-wrap');

        if (liners) {
            // gsap.to(scroller, {
            //     scrollTrigger: {
            //         trigger: scroller,
            //         start: 'top bottom',
            //         end: 'top top',
            //         scrub: 1,
            //     },
            //     x: -300,
            //     ease: 'none',
            // });

            liners.forEach((liner) => {
                const direction = liner.dataset.direction ? liner.dataset.direction : '-';

                gsap.to(liner, {
                    x: `${direction}50%`,
                    ease: 'none',
                    duration: isTablet ? 25 : 55,
                    repeat: -1,
                });
            });
        }

        if (isTablet1) {
            return;
        }

        const imgFloatWrap = gsap.utils.toArray('[data-float-img-mouse] .lieux-split-img');

        imgFloatWrap.forEach((container) => {
            container.addEventListener('mouseenter', (e) => {
                const t1 = gsap.timeline();
                const floatImgWrap = container.querySelector('[data-float-img]');
                t1.to(floatImgWrap, {
                    duration: 0.5,
                    autoAlpha: 1,
                });
            });
            container.addEventListener('mouseleave', () => {
                const tl = gsap.timeline();
                const floatImgWrap = container.querySelector('[data-float-img]');
                // const allFloatImgWrap = gsap.utils.toArray('[data-float-img]');
                // allFloatImgWrap.forEach((img) => {
                //     img.style.opacity = 0;
                // });
                tl.to(floatImgWrap, {
                    duration: 0.5,
                    autoAlpha: 0,
                });
            });
            container.addEventListener('mousemove', (e) => {
                const floatImgWrap = container.querySelector('[data-float-img]');

                const xpos = e.clientX - container.getBoundingClientRect().left + 25;
                const ypos =
                    e.clientY -
                    container.getBoundingClientRect().top -
                    floatImgWrap.clientHeight -
                    45;

                const tl = gsap.timeline();
                tl.to(floatImgWrap, {
                    x: xpos,
                    y: ypos,
                });
            });
        });
    }, 745);
}
