export default function initAddToFavorite() {
    setInterval(() => {
        checkForFavoriteEvents();
    }, 2200);

    checkForFavoriteEvents();
}

function checkForFavoriteEvents() {
    // Add to favorite btns [data-add-to-favorite] attribute ex: <button data-add-to-favorite="1"></button> where 1 is the event id
    // Using localStorage to save favorite events
    const addToFavoriteBtns = document.querySelectorAll('[data-add-to-favorite]');
    const favoriteEvents = localStorage.getItem('gestev-favorite-events')
        ? JSON.parse(localStorage.getItem('gestev-favorite-events'))
        : [];

    addToFavoriteBtns.forEach((btn) => {
        const eventId = btn.getAttribute('data-add-to-favorite');

        const favBtns = document.querySelectorAll('.add-to-favorites');

        if (favoriteEvents.length) {
            favBtns.forEach((favBtn) => {
                favBtn.classList.add('is-active');
            });
        } else {
            favBtns.forEach((favBtn) => {
                favBtn.classList.remove('is-active');
            });
        }

        if (favoriteEvents.includes(eventId)) {
            btn.classList.add('is-active');
        } else {
            btn.classList.remove('is-active');
        }

        btn.addEventListener('click', (e) => {
            e.preventDefault();

            if (favoriteEvents.includes(eventId)) {
                // Remove from favorite
                const index = favoriteEvents.indexOf(eventId);

                if (index > -1) {
                    favoriteEvents.splice(index, 1);
                }

                btn.classList.remove('is-active');
            } else {
                // Add to favorite
                favoriteEvents.push(eventId);
                btn.classList.add('is-active');
            }

            localStorage.setItem('gestev-favorite-events', JSON.stringify(favoriteEvents));
        });
    });
}
